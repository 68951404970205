import React from "react";

interface YoutubeEmbedProps {
  id: string;
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ id }) => {
  const url = "https://www.youtube.com/embed/" + id;
  return (
    <div>
      <iframe width="560" height="315" src={url}></iframe>
    </div>
  );
};

export default YoutubeEmbed;
