import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const projectThumbnailImage = graphql`
  fragment projectThumbnailImage on File {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED)
    }
  }
`;

interface ThumbnailImageProps {
  file: any;
  alt: string;
}

const ThumbnailImage: React.FC<ThumbnailImageProps> = ({ file, alt }) => {
  if (file.childImageSharp && file.childImageSharp.gatsbyImageData) {
    return (
      <GatsbyImage image={file.childImageSharp.gatsbyImageData} alt={alt} />
    );
  }
  return <></>;
};

export default ThumbnailImage;
