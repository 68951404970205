import React from "react";

import Layout from "../../components/layout";
import { Paper, Typography, Link, Button, Grid, styled } from "@mui/material";

import background from "../../images/projects/phobia/background.png";

import { graphql, useStaticQuery } from "gatsby";
import ThumbnailImage from "../../components/thumbnail";
import YoutubeEmbed from "../../components/youtube";

const Root = styled(`div`)(({ theme }) => ({
  backgroundSize: "contain",
  backgroundImage: `url('${background}')`,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(48, 48, 48, .86)",
  color: "#fff",
  padding: theme.spacing(2),
  "h1, h2, h3, h4, h5": {
    color: "#FFFF27",
  },
  "h2, h3, h4, h5": {
    marginTop: theme.spacing(3),
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: "#FFFF27",
}));

const query = graphql`
  query PhobiaQuery {
    betaimage1: file(relativePath: { eq: "projects/phobia/beta/phobia1.png" }) {
      ...projectThumbnailImage
    }

    betaimage2: file(relativePath: { eq: "projects/phobia/beta/phobia2.png" }) {
      ...projectThumbnailImage
    }

    betaimage3: file(relativePath: { eq: "projects/phobia/beta/phobia3.png" }) {
      ...projectThumbnailImage
    }

    betaimage4: file(relativePath: { eq: "projects/phobia/beta/phobia4.png" }) {
      ...projectThumbnailImage
    }

    goldimage1: file(relativePath: { eq: "projects/phobia/gold/phobia1.png" }) {
      ...projectThumbnailImage
    }

    goldimage2: file(relativePath: { eq: "projects/phobia/gold/phobia2.png" }) {
      ...projectThumbnailImage
    }

    goldimage3: file(relativePath: { eq: "projects/phobia/gold/phobia3.png" }) {
      ...projectThumbnailImage
    }

    goldimage4: file(relativePath: { eq: "projects/phobia/gold/phobia4.png" }) {
      ...projectThumbnailImage
    }

    firstplayableimage1: file(
      relativePath: { eq: "projects/phobia/first-playable/phobia1.png" }
    ) {
      ...projectThumbnailImage
    }

    firstplayableimage2: file(
      relativePath: { eq: "projects/phobia/first-playable/phobia2.png" }
    ) {
      ...projectThumbnailImage
    }

    firstplayableimage3: file(
      relativePath: { eq: "projects/phobia/first-playable/phobia3.png" }
    ) {
      ...projectThumbnailImage
    }

    firstplayableimage4: file(
      relativePath: { eq: "projects/phobia/first-playable/phobia4.png" }
    ) {
      ...projectThumbnailImage
    }
  }
`;

const PhobiaPage: React.FC = () => {
  const projectName = `Phobia`;
  const data = useStaticQuery(query);

  return (
    <Root>
      <Layout pageTitle={projectName}>
        <StyledPaper>
          <Typography variant="h4" component="h1">
            {projectName}
          </Typography>
          <Typography variant="body1" component="p">
            Phobia was the game I developed during my junior year. The team that
            created this game consisted of 10 total members. Four artists, one
            lead producer, one lead technical director, one designer, and three
            programmers. I was the lead graphics architect on this project.
            Since this is a heavy art-based game, I was responsible for making
            the art-pipeline work efficiently to handle our open-world
            environments. I was also responsible for managing all of the
            resources needed to display our game using our Asset Management
            System that I wrote.
          </Typography>
          <br />
          <Typography variant="body1" component="p">
            This game project is in Gold state and has been submitted to{" "}
            <StyledLink href="https://www.igf.com/">
              Independent Games Festival
            </StyledLink>
            . Below are a couple of screen shots from different builds
            throughout my junior year.
          </Typography>

          <Typography variant="h5" component="h2">
            Downloads
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://games.digipen.edu/games/phobia"
            target="_blank"
          >
            Download Gold Installer
          </Button>

          <Typography variant="h5" component="h2">
            Gold Release
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ThumbnailImage file={data.goldimage1} alt="Screenshot 1" />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage file={data.goldimage2} alt="Screenshot 2" />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage file={data.goldimage3} alt="Screenshot 3" />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage file={data.goldimage4} alt="Screenshot 4" />
            </Grid>
          </Grid>
          <br />
          <YoutubeEmbed id="665GtJdrEm8" />

          <Typography variant="h5" component="h2">
            Beta Release
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ThumbnailImage file={data.betaimage1} alt="Screenshot 1" />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage file={data.betaimage2} alt="Screenshot 2" />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage file={data.betaimage3} alt="Screenshot 3" />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage file={data.betaimage4} alt="Screenshot 4" />
            </Grid>
          </Grid>
          <br />
          <YoutubeEmbed id="cUBkJ57Y6zs" />

          <Typography variant="h5" component="h2">
            First Playable Release
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ThumbnailImage
                file={data.firstplayableimage1}
                alt="Screenshot 1"
              />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage
                file={data.firstplayableimage2}
                alt="Screenshot 2"
              />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage
                file={data.firstplayableimage3}
                alt="Screenshot 3"
              />
            </Grid>
            <Grid item xs={3}>
              <ThumbnailImage
                file={data.firstplayableimage4}
                alt="Screenshot 4"
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Layout>
    </Root>
  );
};

export default PhobiaPage;
